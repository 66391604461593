/* @import "./styles/layout/_navbar.scss"; */

/* Home Page Style */

/* Font Family */

/* Text Size */

span,
p,
BootstrapDialog,
DialogTitle,
DialogContent,
.css-140puxv-MuiTypography-root-MuiDialogTitle-root {
  font-family: "Gilroy-Regular", sans-serif;
}

/* Webkit Custom */

::-webkit-scrollbar {
  width: 13px;
}

::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #000;
}

::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #5a5959;
}

/* Common */

* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  font-family: "Gilroy-Regular", sans-serif;
  /* background-color: #000; */
}

html {
  height: 100%;
  scrollbar-width: none;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  box-sizing: border-box;
  height: 100%;
  background-color: #fff !important;
  background: #fff;
  font-family: "Gilroy-Regular", sans-serif;
  scrollbar-width: none;
}

.text-primary {
  color: #fe4c1c;
}

.text-second {
  color: #6cbb47;
}

.text-third {
  color: #0160e7;
}

.text-dark {
  color: #1a1b1e;
}

.text-danger {
  color: #1a1b1e;
}

.text-gray {
  color: #a5b7d2;
}

.mt-30 {
  margin-top: 30px;
}
.mb-30 {
  margin-bottom: 30px;
}
.p-10 {
  padding: 10px;
}
.pt-30 {
  padding-top: 30px;
}
.main-heading {
  font-size: 25px;
  margin-top: 0px !important;
  margin-bottom: 10px !important;
  font-family: "Helvetica Neue", sans-serif;
}
.snippet-display-flex {
  display: flex;
}
.logo {
  width: 70px;
}
.ml-15 {
  margin-left: 15px;
}
.font-p2 {
  color: #1a1b1e;
  font-size: 20px;
  font-family: "Gilroy-Regular", sans-serif;
  font-weight: 400;
  margin-top: 0px !important;
  margin-bottom: 5px !important;
}
.section-one-bg {
  background-color: transparent;
  background-image: linear-gradient(
    to right bottom,
    #82e5df,
    #88e1e5,
    #90dde8,
    #9bd8e8,
    #a5d4e6,
    #aad7e2,
    #b0d9de,
    #b8dbdb,
    #c8e2d6,
    #dce7d5,
    #f0ebda,
    #fff0e5
  );
  /* background-image: linear-gradient(315deg, #b9bed7 0%, #77eed8 74%); */
  /* background: url('./images/1.png'); */
  background-size: cover;
}
.section-second-bg {
  background-color: transparent;
  background-image: linear-gradient(
    to right top,
    #82e5df,
    #88e1e5,
    #90dde8,
    #9bd8e8,
    #a5d4e6,
    #aad7e2,
    #b0d9de,
    #b8dbdb,
    #c8e2d6,
    #dce7d5,
    #f0ebda,
    #fff0e5
  );
  /* background-image: linear-gradient(241deg, #aeb5d4 0%, #77eed8 74%); */
  /* background: url('./images/2.png'); */
  background-size: cover;
}
.section-third-bg {
  background-color: transparent;
  background-image: linear-gradient(
    to right bottom,
    #82e5df,
    #88e1e5,
    #90dde8,
    #9bd8e8,
    #a5d4e6,
    #aad7e2,
    #b0d9de,
    #b8dbdb,
    #c8e2d6,
    #dce7d5,
    #f0ebda,
    #fff0e5
  );

  /* background-image: linear-gradient(241deg, #aeb5d4 0%, #77eed8 74%); */
  /* background: url('./images/3.png'); */
  background-size: cover;
}
.section-second-img {
  width: 100%;
  border-radius: 0px 200px 0px 0px;
  height: 600px;
  object-fit: cover;
}
.section-third-img {
  width: 100%;
  border-radius: 200px 0px 0px 0px;
  height: 600px;
  object-fit: cover;
}
.section-second-img-py {
  padding-top: 60px;
  padding-bottom: 100px;
}
.section-third-img-py {
  padding-top: 100px;
  padding-bottom: 100px;
}
.padding-top-20rem {
  padding-top: 12rem;
}
.padding-top-27rem {
  padding-top: 27rem;
}
.sub-title {
  font-size: 30px;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 500 !important;
}
.apps-logo {
  width: 200px;
}
.mr-15 {
  margin-right: 15px;
}
.bottom-50 {
  position: relative;
  bottom: 50px !important;
}
.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.right-15 {
  right: 15px;
  position: relative;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.padding-top-14rem {
  padding-top: 14rem;
}
.footer-dark {
  padding: 50px 0;
  color: #f0f9ff;
  background-color: #282d32;
}

.label {
  align-self: flex-start;
  color: #fff;
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 15px;
}

.input {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  outline: none;
  font-size: 16px;
  margin-bottom: 10px;
}

.phone-input-container {
  position: relative;
  width: 100%;
}

.country-code {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #fff;
  font-size: 16px;
  margin-right: 20;
  padding-right: 4px;
  border-right: 1px solid #fff;
}

.phone-input {
  border: none;
  padding-left: 50px;
}

.footer-dark h3 {
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 16px;
  font-family: "Helvetica Neue", sans-serif;
}

.footer-dark ul {
  padding: 0;
  list-style: none;
  line-height: 1.6;
  font-size: 14px;
  font-family: "Gilroy-Regular", sans-serif;
  margin-bottom: 0;
}
.snippet-display-flex-center {
  display: flex;
  justify-content: center;
}
.footer-dark ul a {
  color: inherit;
  text-decoration: none;
  opacity: 0.6;
}

.footer-dark ul a:hover {
  opacity: 0.8;
}

.footer-dark .item.text {
  margin-bottom: 36px;
}

.footer-dark .item.text p {
  opacity: 0.6;
  margin-bottom: 0;
}

.footer-dark .item.social {
  text-align: center;
}

.footer-dark .item.social > a {
  font-size: 20px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4);
  margin: 0 8px;
  color: #fff;
  font-family: "Helvetica Neue", sans-serif;
  opacity: 0.75;
}

.footer-dark .item.social > a:hover {
  opacity: 0.9;
}

.footer-dark .copyright {
  text-align: center;
  padding-top: 24px;
  opacity: 0.3;
  font-size: 13px;
  font-family: "Gilroy-Regular", sans-serif;
  margin-bottom: 0;
}
.footer-logo-text {
  font-size: 18px;
  font-family: "Helvetica Neue", sans-serif;
  margin-top: 15px;
}
.footer-logo {
  width: 50px;
}
.country-code-border {
  border: 2px solid #93939378;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}
.country-code-number {
  font-size: 15px;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: bold;
  position: relative;
  top: 2px;
}
.phone-number-input {
  background: transparent;
  outline: none;
  font-size: 15px;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: bold;
  border: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.get-button {
  background-color: #000;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#1d1d1d),
    to(#141414)
  );
  background-image: linear-gradient(180deg, #1d1d1d, #141414);
  color: #fff;
  padding: 1rem 1rem;
  border: 1px #000;
  font-size: 17px;
  font-family: "Helvetica Neue", sans-serif;
  border-radius: 5px;
  font-weight: bold;
  width: 95%;
}
.get-button:hover {
  -webkit-filter: brightness(200%);
  filter: brightness(200%);
  outline-offset: 0px;
  outline: #fff 0.1em;
}
#formSuccess {
  display: none;
}
#errorMsg {
  color: #eb445a;
  display: none;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.ml-20 {
  margin-left: 20px;
}
.banner-img {
  width: 300px;
}
.flex-switch {
  display: flex;
}

.petit-formal-script-regular {
  font-family: "Gilroy-Regular", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.petit-font {
  font-family: "petit-formal";
  font-weight: 400;
  font-size: xx-large !important;
  position: relative;
  top: 5px;
}
.submit-button {
  background: linear-gradient(90deg, #000aff, #d500f9);
  border: none;
  border-radius: 50px;
  color: white;
  padding: 10px 30px !important;
  font-size: 18px;
  font-family: "Helvetica Neue", sans-serif !important;
  font-weight: bold;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2),
    /* Light shadow for depth */ 0 2px 4px rgba(253, 251, 251, 0.3) inset; /* Inner shadow for raised effect */
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 16px;
}

.outline-now-button-full {
  background: transparent;
  display: inline-block;
  font-family: "Helvetica Neue", sans-serif !important;
  /* background-image: linear-gradient(90deg, #000aff, #db00ff); */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  padding: 18px 26px;
  border-radius: 10px;
  text-decoration: none;
  color: #000 !important;
  font-size: 16px !important;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 600;
  border: 1px solid transparent; /* Set border to transparent */
  background-image: linear-gradient(white),
    linear-gradient(to right, #000aff, #d500f9); /* Gradient border */
  background-origin: border-box;
  background-clip: padding-box, border-box;
  border-image-source: linear-gradient(
    to right,
    #000aff,
    #d500f9
  ); /* Gradient border */
  border-image-slice: 1;
}

.saving-now-button {
  background: linear-gradient(
    90deg,
    #000aff,
    #d500f9
  ); /* Blue to purple gradient */
  border: none;
  border-radius: 50px; /* Fully rounded corners */
  /* Adjusts button size */
  color: white;
  padding: 15px 30px !important;
  font-size: 18px;
  font-family: "Helvetica Neue", sans-serif !important;
  font-weight: bold;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2),
    /* Light shadow for depth */ 0 2px 4px rgba(253, 251, 251, 0.3) inset; /* Inner shadow for raised effect */
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 16px;
  margin: 8px 0px;
}

.saving-now-button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2),
    /* Light shadow for depth */ 0 2px 4px rgba(253, 251, 251, 0.3) inset; /* Slightly darker shadow on hover */
  transform: scale(1.05); /* Grow slightly on hover */
}

.font-size-56 {
  font-family: "Helvetica Neue", sans-serif;
  font-size: 56px;
  color: #ffffff;
  font-weight: bold;
}

.header-section {
  .left-part {
    padding-top: 5rem;
    padding-left: 3rem;
    min-height: 99vh;
  }
  .right-part {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    padding-top: 3rem !important;
    /* padding-bottom: 3rem !important; */
  }
}
.title {
  font-family: "Helvetica Neue", sans-serif;
  font-size: 16px;
  color: red;
}
.noscroll-x {
  overflow-x: hidden !important;
}
.main-part {
  margin-top: 0rem;
  .main-content {
    font-family: "Helvetica Neue", sans-serif;
    font-size: 50px;
    color: #ffffff;
    line-height: normal;
    font-weight: bold;
  }
  .sub-content {
    font-family: "Helvetica Neue", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
  }
}

.saving-now-butto2 {
  font-family: "Helvetica Neue", sans-serif;
  font-size: 20px;
  background-color: beige;
  border-radius: 30px;
}

.cal-card {
  height: 100%;
  background-color: #d9d9d9;
  width: 100%;
  border-radius: 16px;
}

/* h3 {
  font-size: 36px;
}

h4 {
  font-size: 30px;
  font-weight: 00 !important;
}
p {
  font-size: 18px;
  margin-bottom: 0px !important;
} */
.section {
  margin-top: 4rem;
  margin-bottom: 3rem;
}

.card-bg {
  background-color: #d9d9d9;
  height: 100%;
}
.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  color: #000;
  box-shadow: none;
}
.accordion-button:not(.collapsed)::after {
  transform: var(--bs-accordion-btn-icon-transform);
  background-color: #0052b4;
  padding: 10px;
  border-radius: 30px;
}
.accordion-body {
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
}

.accordion-body.show {
  max-height: 1000px; /* A high enough value to accommodate the content */
  opacity: 1;
}
.accordion-item {
  border-bottom: 1px solid #dcdbdb;
}

.accordion {
  --bs-accordion-border-color: transparent;
  --bs-accordion-border-width: 0px;
}

.backgroud-gradient-image {
  /* background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 35%,
    rgba(0, 212, 255, 1) 100%
  ); */
  background: url("./assets/images/footer_bg.svg");
  background-size: cover;
}

.footer-background-gradient-image {
  /* background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 35%,
    rgba(0, 212, 255, 1) 100%
  ); */
  background: url("./assets/images/footer_bg.svg");
  background-size: cover;
}
.position-top-4 {
  position: relative;
  top: 4px;
}
.text-size-16 {
  font-size: 16px;
  font-weight: 500;
}

.gradient-text-small {
  background: linear-gradient(90deg, #000aff 0%, #db00ff 54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
  font-weight: 600;
  font-family: "Helvetica Neue", sans-serif;
}
.payment-second-section {
  /* background-color: #fff;
  display: flex;
  overflow-y: scroll;
  height: 100vh;
  scrollbar-width: none; */
  /* background-image: url("./assets/images/landing_banner.png");  */
  background-image: url("./assets/images/landing_banner.png");
  background-attachment: fixed;
  background-position: right;
  background-repeat: no-repeat;
  background-size: 80%;
  min-height: 10vh;
  background-color: #f8f8fb;
}
.white-card {
  box-shadow: 0px 1px 24px 0px #1317f014;
  border-radius: 16px;
  padding: 16px;
  background-color: #fff;
  height: max-content !important;
}
.testimonial-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align items to the top */
  padding: 10px;
  box-shadow: 0px 1px 24px 0px #1317f014;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  flex-grow: 1;

  .avatarIcon {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    /* margin: 10px 0px; */
    max-width: 100px; /* Control the image size */
    margin: 0 auto 10px;
  }
  .name {
    font-size: 14px;
    color: #000;
    font-weight: 500;
  }
  .traderType {
    font-size: 14px;
    color: #919191;
    font-weight: 500;
  }
  .comments {
    font-size: 14px;
    color: #919191;
    font-weight: 500;
  }
  p {
    margin-bottom: 6px !important;
  }
}
.text-size-18 {
  font-size: 18px;
  font-weight: 600;
  font-family: "Helvetica Neue", sans-serif;
}
.border-gray-circle {
  border: 2px dashed #e5e5e5;
  border-radius: 50px;
  color: #e5e5e5;
  width: 50px;
  height: 50px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  align-content: center;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  font-family: "Helvetica Neue", sans-serif;
}
.border-top-line {
  border-top: 2px dashed #e5e5e5;
  width: 250px;
}
.center-align {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.snippet-pl-20 {
  padding-left: 30px;
}
.snippet-pr-20 {
  padding-right: 30px;
}
.snippet-border-left {
  border-left: 1px solid #e5e5e5;
}
.snippet-justify-center {
  justify-content: center;
}
.card-width-400 {
  width: 480px;
}
.snippet-padding-top {
  /* padding-top: 17px; */
  border-left: 1px solid #e5e5e5;
  /* border-radius: 5px; */
  /* padding-left: 11px; */
  /* padding-right: 10px; */
}
.snippet-padding-top-17 {
  padding-top: 0px;
  border-right: 1px solid #e5e5e5;
  /* border-radius: 5px; */
  padding-left: 11px;
  padding-right: 10px;
}
.w-90 {
  width: 90%;
}
.react-date-picker__wrapper {
  border: unset !important;
}
.snippet-border-gray {
  border: 1px solid #e5e5e5;
  padding: 6px 16px;
  border-radius: 5px;
  line-height: 20px !important;
  border-radius: 12px;
}
.react-date-picker__button svg {
  display: none !important;
}
.datepicker-wrapper {
  .react-datepicker {
    background-color: white;
    color: black;
  }

  .react-datepicker__input-container input {
    color: black;
  }

  .react-datepicker__month-container {
    color: black;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    color: black;
  }
  .react-date-picker__button svg {
    display: none;
  }
}
.text-size-20 {
  font-size: 20px;
  font-weight: 600;
  font-family: "Helvetica Neue", sans-serif;
}
.snippet-space-between {
  justify-content: space-between;
}
.snippet-bg-voilet {
  background: #1317f01f;
  padding: 8px 15px;
  border-radius: 5px;
}
.text-size-16-semi {
  font-size: 14px;
  font-weight: 700;
  font-family: "Helvetica Neue", sans-serif;
}
.cal-amount-font {
  font-size: 16px;
  margin-bottom: 0px !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}
.form-control {
  padding: 6px 16px !important;
  border: none !important;
  font-size: 14px !important;
  font-weight: normal !important;
  line-height: 26px !important;
  border-radius: 12px !important;
}
.snippet-border-gray-input {
  border: 1px solid #e5e5e5;
  border-radius: 12px !important;
}
.snippet-pointer {
  cursor: pointer;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.title-heading {
  font-size: 40px;
  font-weight: bold;
  font-family: "Helvetica Neue", sans-serif;
}
.retry-now-button-full {
  text-align: center;
  background: rgb(253, 29, 29);
  background: linear-gradient(
    90deg,
    rgba(253, 29, 29, 1) 50%,
    rgba(252, 113, 55, 1) 100%,
    rgba(252, 156, 69, 1) 100%
  );
  border: none;
  border-radius: 50px; /* Fully rounded corners */
  padding: 15px 30px; /* Adjusts button size */
  color: white;
  font-size: 18px;
  font-weight: bold;
  width: 200px;
  font-family: "Helvetica Neue", sans-serif !important;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2),
    /* Light shadow for depth */ 0 2px 4px rgba(253, 251, 251, 0.3) inset; /* Inner shadow for raised effect */
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 16px;
}
.saving-now-button-full {
  background: linear-gradient(
    90deg,
    #0d47a1,
    #d500f9
  ); /* Blue to purple gradient */
  border: none;
  border-radius: 50px; /* Fully rounded corners */
  padding: 15px 30px; /* Adjusts button size */
  color: white;
  font-size: 14px !important;
  font-weight: 500;
  width: 100%;
  font-family: "Helvetica Neue", sans-serif !important;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2),
    /* Light shadow for depth */ 0 2px 4px rgba(253, 251, 251, 0.3) inset; /* Inner shadow for raised effect */
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 10px 0px;
  line-height: 16px;
}
.saving-now-button-full:disabled {
  background: #80808099; /* Gray background when disabled */
  color: #ffffff; /* Lighter color for text when disabled */
  cursor: not-allowed; /* Change cursor when disabled */
  box-shadow: none; /* Remove shadow when disabled */
  margin: 10px 0px;
}
.section {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.logoStyle {
  height: 75px;
  width: 75px;
  margin: 12px 0px;
  font-family: "Helvetica Neue", sans-serif !important;
}

.stepCard {
  background-color: white;
  padding: 12px 30px;
  border-radius: 24px;
  box-shadow: 0px 1px 24px 0px #1317f014;
  margin: 2rem 0;
  position: relative;
  overflow: hidden;
  /* cursor: pointer; */
}

.leftContent {
  font-size: 28px;
  font-family: "Helvetica Neue", sans-serif !important;
  font-weight: 600;
}
.cust-carousel {
  display: flex;
  overflow-x: scroll;
  .carousel-card {
    background-color: #000;
    border-radius: 12px;
    padding: 24px;
    .avatarImg {
      border-radius: 50%;
      width: 100px;
      height: 100px;
    }
    .name {
      font-size: 12px;
      color: #130000;
      font-weight: 500;
      font-family: "Helvetica Neue", sans-serif !important;
    }
    .traderType {
      font-size: 12px;
      color: #919191;
    }
    .comments {
      font-size: 12px;
      color: #919191;
      font-family: "Helvetica Neue", sans-serif !important;
    }
  }
}

.cardContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.section-div {
  padding: 8rem 0rem;
}
.FAQ-section {
  padding: 10rem 0rem;
}
.a-tag {
  font-size: 14px;
  margin: 6px 0px;
  color: #0052b4;
  font-weight: 500;
  font-family: "Helvetica Neue", sans-serif !important;
  img {
    font-size: 14px;
  }
}
.table-section {
  margin: 2rem 0px !important;
  .d-flex {
    display: flex;
    padding: 30px 20px !important;
  }
}
.tableHead {
  background-color: #062244 !important;
  color: #fff !important;
  padding: 0rem 2rem !important;
}
.px-2 {
  padding: 0px 8px;
}
.borderBottom {
  border-bottom: 0.5px solid #dcdbdb !important;
  padding: 10px !important;
}
.table {
  border-radius: 12px;
  overflow: hidden;
}
.table > :not(caption) > * > * {
  padding: 1rem 1rem !important;
}
tr {
  border-style: none !important;
}
td {
  border-style: none !important;
}
th {
  border-style: none !important;
}
.cardbg {
  background-color: #062244;
  padding: 3rem;
  border-radius: 24px;
}
.gCFont {
  font-size: 22px;
  font-weight: 600;
  display: inline-block;
  font-family: "Helvetica Neue", sans-serif !important;
  background-image: linear-gradient(90deg, #000aff, #db00ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.font-gradient-text {
  display: inline-block;
  font-family: "Helvetica Neue", sans-serif !important;
  background-image: linear-gradient(90deg, #000aff, #db00ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.helvetica-font {
  font-family: "Helvetica Neue", sans-serif !important;
}
.text-size-22 {
  font-size: 22px;
  font-weight: 500;
  font-family: "Helvetica Neue", sans-serif !important;
}
.font-size-24 {
  font-size: 20px;
  font-weight: 400;
  font-family: "Helvetica Neue", sans-serif !important;
}

.font-large {
  font-size: 46px;
  font-weight: 600;
  padding: 10px 0px;
  margin-bottom: 0px !important;
  font-family: "Helvetica Neue", sans-serif !important;
  line-height: normal;
}

.font-size-20 {
  font-size: 20px;
  font-weight: 400;
  font-family: "Helvetica Neue", sans-serif !important;
  color: #484c5f;
  margin-bottom: 0px !important;
}
.btn-blue-bg {
  background: #0052b4;
  padding: 10px 26px;
  border-radius: 30px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  border: none !important;
  margin: 2rem 0px;
  font-family: "Helvetica Neue", sans-serif !important;
}
/* 
.header-section {
  margin-left: 3rem;
  margin-right: 1rem;
  padding-top: 8rem !important;

  .p1 {
    font-size: 11px;
    color: #ffffff;
  }
  .p2 {
    font-size: 13px;
    color: #ffffff;
    margin-top: 2rem;
  }
} */

.font-size-48 {
  font-size: 48px;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 600;
}

/* // New Css */

.completed-circle {
  background-color: #0052b4;
  color: #fff !important;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
}
.snippet-input-box {
  width: 100%;
}
.snippet-border-bottom-gray {
  border-bottom: 1px solid #e5e5e5;
}
.snippet-bg-yellow {
  background: #fff0c9;
  padding: 15px;
  border-radius: 5px;
}
.snippet-bg-red {
  background: #ffc9c9;
  padding: 15px;
  border-radius: 5px;
}
.text-size-18-normal {
  font-size: 14px;
  font-weight: 500;
  font-family: "Helvetica Neue", sans-serif;
}
.grandient-badge {
  background: linear-gradient(90deg, #0052b4 0%, #db00ff 54%);
  padding: 7px 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
}
.snippet-bg-voilet-square {
  background: #e3e3fd;
  padding: 15px;
  border-radius: 5px;
  margin-top: 12px !important;
}
.snippet-bg-gray-square {
  background: #0000000d;
  padding: 15px;
  border-radius: 5px;
}
.snippet-bg-red-square {
  background: #ffc9c9;
  padding: 15px;
  border-radius: 5px;
}
.text-size-21 {
  font-size: 20px;
  font-weight: 600;
  font-family: "Helvetica Neue", sans-serif;
}
.snippet-align-content-center {
  align-content: center;
}
.snippet-bg-white {
  background: #fff !important;
}
.web-d-flex {
  display: flex;
  padding: 10px;
}
/* .snippet-width-90 {
  width: 90% !important;
} */

.border {
  border-top: 1px solid red !important;
  border-bottom: 1px solid red !important;
  margin: 4rem 0 0 0 !important;
}
.align-item-center {
  display: flex;
  align-items: center;
}
.space-y {
  padding: 5rem 0rem !important;
}
.footer-sec {
  padding-top: 6rem;
}
.copy-rights {
  padding: 2rem !important;
  text-align: center;
}
.snippet-country-code {
  /* padding-top: 10px; */
  padding-left: 6px;
  border-right: 1px solid #e5e5e5;
  padding-right: 13px;
  margin: 6px;
}
.arrow-btn {
  position: relative;
  bottom: 1px;
  padding: 0px 4px;
  height: 10px;
}
.security-measure-alignment {
  text-align: left;
  padding: 1rem !important;
}
.starLogo {
  padding: 0px 4px;
  height: 16px;
}
.steps-description {
  font-size: 20px !important;
  color: #919191;
  font-weight: 500;
  margin-bottom: 0px !important;
  /* max-height: 0; */
  opacity: 1;
  transition: max-height 2s ease, opacity 1s ease;
  /* margin-top: 10px; */
}
/* .stepCard:hover .steps-description {
  max-height: 100px;
  opacity: 1;
} */
.text-size-14 {
  font-size: 14px;
  font-weight: 400;
}
.text-size-12 {
  font-size: 12px;
  font-weight: 400;
}
.text-light-gray {
  color: #919191;
}
.font-size-30 {
  font-size: 30px;
  font-weight: 500;
  font-family: "Helvetica Neue", sans-serif;
  color: #fff;
  padding-top: 20px;
}
.web-justify-center {
  justify-content: center;
}
.error-message {
  padding-top: 5px;
  color: #e92c2c;
  font-size: 15px;
}
.snippet-d-justify-between {
  display: flex;
  justify-content: space-between;
}

.d-flex {
  display: flex;
}
.testimonial-card-d-flex {
  display: flex;
  align-items: stretch; /* Make all items the same height */
  gap: 20px; /* Optional: Add space between cards */
}

.card-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 150px;
}

.Name,
.traderType,
.comments {
  margin: 5px 0; /* Spacing between elements */
}
.Faqicon {
  height: 10px;
  width: 20px;
}
.snippet-text-dark {
  color: #000;
}
.align-content-center {
  justify-content: center;
  align-items: center;
  display: flex;
}
.snippet-width-200 {
  width: 150px;
}
.text-gray-dark {
  color: #414142;
}
.padding-x-12px {
  padding: 12px 0px;
}
.bg-map-image {
  min-height: 100vh;
}
.paymentImg {
  width: 84%;
}
.floating-button {
  visibility: hidden;
  position: fixed;
}
.custom-placeholder::placeholder {
  color: #919191 !important; /* Change placeholder color */
  font-weight: 400 !important;
}
.justify-content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fixed-header {
  position: fixed;
  top: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.mbl-view {
  padding-top: 50px;
}
.responsive-div {
  height: 56px;
}
.FAQcontainer .question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #2a2a2a;
  font-size: 18px;
  font-family: "Helvetica Neue", sans-serif;
}

.FAQcontainer .questionText {
  flex: 1;
  font-weight: 600;
}

.FAQcontainer .icon {
  height: 22px !important;
  color: #1e73e8;
}

.FAQcontainer .answer {
  padding: 10px 0 0;
  color: #666;
  font-size: 16px;
}

.snippets-font-size-28 {
  font-size: 28px;
}
.snippets-font-weight-500 {
  font-weight: 500;
}

.note-size-10 {
  font-size: 12px;
  font-weight: 600;
  color: gray;
  margin-bottom: 0px !important;
}
@media (max-width: 450px) {
  .main-heading {
    font-size: 20px;
    font-family: "Helvetica Neue", sans-serif;
  }
  .jp-logo {
    width: 120px !important;
  }
  .font-size-34 {
    font-size: 26px !important;
    font-weight: 500;
    font-family: "Helvetica Neue", sans-serif;
  }
  .snippet-sheild-img {
    width: 100px !important;
    height: 100px !important;
  }
  .snippet-mbl-img-left {
    text-align: left !important;
  }
  .font-size-30 {
    font-size: 24px !important;
    font-weight: 500;
    font-family: "Helvetica Neue", sans-serif;
    color: #fff;
    padding-top: 10px !important;
  }
  .jp-morgan-logo {
    height: 17px !important;
  }
  .fx-font-size-24 {
    font-size: 16px !important;
    font-weight: 600;
  }
  .snippet-text-align-center {
    position: relative;
    top: 10px;
  }
  .text-size-16 {
    font-size: 15px !important;
  }
  .novapaylogo-position {
    position: absolute;
    top: 30px;
    width: 40%;
  }
  .footer-sec {
    padding-top: 2rem !important;
  }
  .responsive-div {
    height: 100px;
  }
  .scroll-behaviour {
    overflow-y: hidden !important;
    height: max-content !important;
  }
  .row-reverse {
    display: flex;
    flex-direction: column-reverse;
  }
  .mbl-mt-3 {
    margin-top: 20px;
  }
  .web-d-flex {
    display: unset !important;
    padding: 10px;
  }
  .snippet-width-90 {
    width: 100%;
  }
  .section-div {
    padding: 1rem 0rem !important;
  }
  .FAQ-section {
    padding: 4rem 0rem !important;
  }
  .font-p2 {
    font-size: 16px;
    font-family: "Helvetica Neue", sans-serif;
  }
  .logo {
    width: 60px;
  }
  .padding-top-20rem {
    padding-top: 0px !important;
  }
  .padding-top-27rem {
    padding-top: 0px !important;
  }
  .mobile-text-center {
    text-align: center;
  }
  .mobile-d-flex-center {
    display: flex;
    justify-content: center;
  }
  .mobile-justify-center {
    justify-content: center;
    display: flex;
  }
  .flex-switch {
    display: flex;
    flex-direction: column-reverse;
  }
  .section-third-img-py {
    padding-top: 40px;
    padding-bottom: 100px;
  }
  .font-large {
    font-size: 24px !important;
    padding: 0px 0px !important;
  }
  .security-measure-alignment {
    text-align: center !important;
    padding: 0px !important;
  }
  .payment-second-section {
    background-image: url("./assets/images/backgroundFrame.png") !important;
  }
  .payment-second-sectionV2 {
    background-image: url("./assets/images/backgroundFrame.png") !important;
    background-repeat: no-repeat;
    background-size: 100%;
    min-height: 10vh;
    background-attachment: fixed;
    background-color: #f8f8fb;
    background-position: right;
  }
  .mbl-view {
    padding-top: 120px !important;
  }
  .fixed-header {
    height: 100px !important;
  }
  .left-part {
    min-height: 0 !important;
    padding-left: 1rem !important;
    padding-top: 0 !important;
    padding-right: 1rem !important;
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .card-width-400 {
    width: 100%;
  }
  .padding-x-12px {
    padding: 0px !important;
  }
  .text-size-18 {
    font-size: 14px;
  }
  .snippet-pr-20 {
    padding-right: 20px !important;
  }
  .snippet-pl-20 {
    padding-left: 20px !important;
  }
  .text-size-16-semi {
    font-size: 12px !important;
  }
  .gradient-text-small {
    font-size: 16px !important;
  }
  .text-size-20 {
    font-size: 18px !important;
  }
  .form-control {
    padding: 8px !important;
    font-size: 14px !important;
  }
  .border-top-line {
    width: 190px !important;
  }
  .testimonial-card-d-flex {
    flex-direction: column !important;
  }
  .stepCard {
    padding: 6px 32px !important;
    margin: 1rem 0 !important;
  }
  .leftContent {
    font-size: 18px !important;
  }
  .steps-description {
    font-size: 14px !important;
    margin-top: 0px !important;
  }
  .font-size-48 {
    font-size: 30px !important;
    margin-bottom: 0px !important;
  }
  .bg-map-image {
    max-height: 70vh !important;
    min-height: 70vh !important;
  }
  .snippets-font-size-28 {
    font-size: 22px !important;
  }
  .snippets-font-weight-500 {
    font-weight: 500;
  }
  .section-second-img-py {
    padding-top: 12px !important;
  }
  .font-size-20 {
    font-size: 14px !important;
  }
  .right-part {
    background-color: #f8f8fb !important;
    .paymentImg {
      width: 0px !important;
    }
  }
  .right-part-v2 {
    .paymentImg {
      width: 0px !important;
    }
  }
  .video-height {
    height:auto;
    width: 95% !important;
  }
  .white-card {
    margin-top: 10px !important;
  }
  .space-y {
    padding: 2rem 0rem !important;
  }
  .FAQcontainer .answer {
    font-size: 12px !important;
    padding: 2px 0 0;
  }
  .FAQcontainer .question {
    font-size: 14px !important;
  }
  .FAQcontainer .item {
    padding: 8px 0px;
    border-bottom: 1px solid #e0e0e0;
  }
  .gCFont {
    font-size: 16px !important;
  }
  .stepCard {
    .gCFont {
      font-size: 30px !important;
    }
  }
  .mbl-font {
    font-size: 26px !important;
  }
  .cardbg {
    padding: 1rem !important;
  }
}
@media (max-width: 450px) {
  .main-content {
    font-size: 30px !important;
    margin-top: 1rem !important;
  }

  .font-size-56 {
    font-size: 34px !important;
    color: #ffffff;
    font-weight: bold;
  }
  .sub-content {
    font-size: 10px !important;
    margin-top: 0rem !important;
  }
  .saving-now-button {
    /* font-size: 14px !important;
    padding: 14px 24px !important;
    margin: 20px 0px 10px 0px; */
    display: none !important;
  }
  .saving-now-butto2 {
    font-size: 16px;
  }
  .section {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .petit-font {
    font-size: x-large !important;
  }
  .floating-button {
    position: fixed !important;
    bottom: 0px !important;
    right: 10px !important;
    visibility: visible !important;
  }
  .btn-blue-bg {
    margin: 1rem 0rem;
  }
  .cal-amount-font {
    font-size: 16px !important;
  }
  .snippet-video-player {
    width: 100% !important; /* Default size for mobile */
    height: 100% !important; /* Default size for mobile */
  }
}
@media (min-width: 450px) and (max-width: 991px) {
  .section-third-img-py {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .padding-top-20rem {
    padding-top: 0px;
  }
  .padding-top-27rem {
    padding-top: 10px;
  }
  .flex-switch {
    display: flex;
    flex-direction: row;
  }
  .paymentImg {
    width: 50% !important;
  }
  .mobile-text-center {
    text-align: center;
  }
  .mobile-d-flex-center {
    display: flex;
    justify-content: center;
  }
  .main-content {
    font-size: 30px !important;
  }
  .sub-content {
    font-size: 16px !important;
  }
  .section {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .font-size-56 {
    font-size: 40px !important;
  }
  .largr-font {
    font-size: 30 !important;
  }
  .header-section {
    .left-part {
      height: fit-content !important;
      margin-top: 0rem !important;
      margin-bottom: 0rem !important;
      min-height: max-content !important;
    }
    .right-part {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
  }
  .main-part {
    margin-top: 0rem !important;
  }
  .snippet-video-player {
    width: 700px !important; /* Fixed width for web */
    height: 400px !important; /* Fixed height for web */
  }
}
@media (max-width: 767px) {
  .footer-dark .item:not(.social) {
    text-align: center;
    padding-bottom: 20px;
  }
  .footer-dark .item.text {
    margin-bottom: 0;
  }
}
@media (max-width: 991px) {
  .footer-dark .item.social {
    text-align: center;
    margin-top: 20px;
  }
}
@media (min-width: 991px) and (max-width: 1024px) {
  .main-content {
    font-size: 45px !important;
  }
  .header-section {
    padding: 10px !important;
  }
  .sub-content {
    font-size: 16px !important;
  }
}
@media (min-width: 1024px) and (max-width: 1366px) {
  .header-section {
    padding: 10px !important;
  }
}
.jp-morgan-logo {
  height: 22px;
}
.fx-font-size-24 {
  font-size: 24px;
  font-weight: 600;
}
.snippet-sheild-img {
  width: 280px;
  height: 280px;
}
.snippet-mbl-img-left {
  text-align: end;
}
.font-size-34 {
  font-size: 34px;
  font-weight: 600;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 6px 16px !important;
}
.jp-logo {
  width: 200px;
}
.snippet-full-width {
  width: 100%;
}
.rbi-logo {
  width: 50px;
}
.rbi-logo-new {
  width: 200px;
}

.video-section {
  display: flex;
  justify-content: center;
}
.video-height {
  height:auto;
  width: 70%;
}

